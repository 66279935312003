
import { defineComponent, PropType } from 'vue';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { ChartCard, Field, MiniProgress, MiniBar, MiniArea } from './charts';
import Yuan from './yuan.vue';
import Trend from './trend/index.vue';
import numeral from 'numeral';
import { VisitDataItem } from '@/api/dashboard/analysis';

const gridLayout = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 6,
};

export default defineComponent({
  name: 'IntroduceRow',
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    visitData: {
      type: Array as PropType<VisitDataItem[]>,
      required: true,
    },
  },
  setup() {
    return {
      gridLayout,
      numeral,
    };
  },
  components: {
    ChartCard,
    Field,
    Trend,
    MiniProgress,
    MiniBar,
    MiniArea,
    Yuan,

    // icons
    InfoCircleOutlined,
  },
});
