import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75439f32"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_caret_up_outlined = _resolveComponent("caret-up-outlined")!
  const _component_caret_down_outlined = _resolveComponent("caret-down-outlined")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.className),
    title: typeof _ctx.$slots.default === 'string' ? _ctx.$slots.default : ''
  }, [
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.flag)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.flag)
        }, [
          (_ctx.flag === 'up')
            ? (_openBlock(), _createBlock(_component_caret_up_outlined, { key: 0 }))
            : (_openBlock(), _createBlock(_component_caret_down_outlined, { key: 1 }))
        ], 2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}